import axios from "axios";
import { AuthConstants } from "../constants/AuthConstants";
import { AlertConstants } from "../constants/AlertConstants";

/**
 * Error processing and message
 * processing from backend
 */

function createErrorMessage(error) {
  if (!error.response) {
    return "PIA error please contact support";
  }

  switch (error.response.status) {
    case 403:
      return "You do not have the correct permission to do this action";
    case 404:
      return "Resource not found";
    default:
      return "PIA error please contact support";
  }
}

function toggleLoading(style) {
  let piaLoader = document.getElementById("pia-loader");
  if (piaLoader !== null) {
    piaLoader.style.display = style;
  }
}

class HttpInterceptor {
  constructor(authStore, alertStore, routerStore, trackingHelper) {
    let pendingRequests = 0;
    axios.interceptors.request.use(
      config => {
        pendingRequests++;
        if (
          config.url.indexOf("actuator/info") === -1 &&
          config.url.indexOf("lookup/") === -1 &&
          config.url.indexOf("keepalive") === -1 &&
          !routerStore.isLoginPage &&
          !routerStore.isLoadingReportPage
        ) {
          toggleLoading("block");
        }
        return authStore.getAccessToken().then(
          accessToken => {
            let accessTokenString = !!accessToken ? accessToken.accessToken : null;
            let boEncodedAuth = localStorage.getItem(AuthConstants.KEYS.PIA_BO_ENCODED_AUTH);
            let boToken = localStorage.getItem(AuthConstants.KEYS.PIA_BO_TOKEN);
            config.headers.Authorization = `Bearer ${accessTokenString}`;

            if (boEncodedAuth) {
              config.headers[AuthConstants.KEYS.PIA_BO_ENCODED_AUTH] = boEncodedAuth;
            }

            if (boToken) {
              config.headers[AuthConstants.KEYS.PIA_BO_TOKEN] = boToken;
            }

            return Promise.resolve(config);
          },
          error => {
            let boEncodedAuth = localStorage.getItem(AuthConstants.KEYS.PIA_BO_ENCODED_AUTH);
            let boToken = localStorage.getItem(AuthConstants.KEYS.PIA_BO_TOKEN);

            config.headers.Authorization = `Bearer ${authStore.accessToken}`;

            if (boEncodedAuth) {
              config.headers[AuthConstants.KEYS.PIA_BO_ENCODED_AUTH] = boEncodedAuth;
            }

            if (boToken) {
              config.headers[AuthConstants.KEYS.PIA_BO_TOKEN] = boToken;
            }

            return Promise.resolve(config);
          }
        );
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function(response) {
        pendingRequests--;
        if (pendingRequests === 0) {
          toggleLoading("none", routerStore.isLoginPage);
        }
        if (response && response.data && response.data.alerts) {
          alertStore.createResponseAlerts(response.data.alerts);
        }
        return response;
      },
      function(error) {
        pendingRequests--;
        if (pendingRequests === 0) {
          document.getElementById("pia-loader").style.display = "none";
        }
        if (error) {
          if (error.response && error.response.data && "invalid_token" === error.response.data.error) {
            authStore.refresh();
            return Promise.reject(error);
          } else if (
            error.response &&
            ((error.response.data && "invalid_grant" === error.response.data.error) || 401 === error.response.status)
          ) {
            if (authStore.loggedIn && !authStore.isLoggingOut) {
              trackingHelper.track("Logout: " + error.response.status);
            }

            authStore.logout();
            return Promise.reject(error);
          } else {
            if (error.response && error.response.data && (error.response.data.warnings || error.response.data.errors)) {
              alertStore.openErrorListModal(error.response);
              return Promise.reject(error);
            }

            alertStore.addAlert({
              type: AlertConstants.TYPES.DANGER,
              text: createErrorMessage(error)
            });

            return Promise.reject(error);
          }
        }
      }
    );
  }
}

export default HttpInterceptor;
