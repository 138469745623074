import React, { Component } from "react";
import { SecureRoute, Security } from "@okta/okta-react";
import { Provider } from "mobx-react";
import { Route, Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import AdminToolsContainer from "./containers/admin/AdminToolsContainer";
import Callback from "./components/login/Callback";
import CannedReportContainer from "./containers/reports/CannedReportContainer";
import CompContainer from "./containers/comparisons/CompContainer";
import CompFinderContainer from "./containers/comp-finder/CompFinderContainer";
import EditCompContainer from "./containers/comparisons/EditCompContainer";
import EditPlayerListContainer from "./containers/players/EditPlayerListContainer";
import EditStatContainer from "./containers/statistics/EditStatContainer";
import EditStatListContainer from "./containers/statistics/EditStatListContainer";
import HelpfulLinkContainer from "./containers/reports/HelpfulLinkContainer";
import HomeContainer from "./containers/HomeContainer";
import HomePlateContainer from "./containers/home-plate/HomePlateContainer";
import LoginContainer from "./containers/LoginContainer";
import NewsFeedContainer from "./containers/news-feeds/NewsFeedContainer";
import OktaAuthHelper from "./components/login/OktaAuthHelper";
import PiaAlerts from "./components/alerts/PiaAlerts";
import PiaLoader from "./utilities/PiaLoader";
import PiaNavigation from "./components/navigation/PiaNavigation";
import PlayerListContainer from "./containers/players/PlayerListContainer";
import PlayerProfileContainer from "./containers/players/PlayerProfileContainer";
import PlayerQueryContainer from "./containers/players/PlayerQueryContainer";
import PlayerContainer from "./containers/players/PlayerContainer";
import ReportLoadingContainer from "./containers/reports/ReportLoadingContainer";
import RootStore from "./stores/RootStore";
import SavedReportContainer from "./containers/reports/SavedReportContainer";
import SharingContainer from "./containers/sharing/SharingContainer";
import StatContainer from "./containers/statistics/StatContainer";
import StatListContainer from "./containers/statistics/StatListContainer";
import Theme from "./Theme";
import { RouteConstants } from "./constants/RouteConstants";

const rootStore = new RootStore();

class App extends Component {
  componentDidMount() {
    function keepAlive() {
      setTimeout(() => {
        let { piaApi, authStore } = rootStore;
        if (authStore.loggedIn === true) {
          piaApi.keepBoAlive();
        }

        keepAlive();
      }, 300000);
    }

    keepAlive();
  }

  render() {
    return (
      <React.Fragment>
        <Provider rootStore={rootStore}>
          <Router history={rootStore.history}>
            <ThemeProvider theme={Theme}>
              <Security className="security-container" auth={rootStore.authApi.oktaAuth}>
                <OktaAuthHelper />
                <PiaNavigation>
                  <SecureRoute path={RouteConstants.ADMIN_TOOLS.route} component={AdminToolsContainer} />
                  <SecureRoute path={RouteConstants.CANNED_REPORTS.route} component={CannedReportContainer} />
                  <SecureRoute path={RouteConstants.COMPARISONS.route} component={CompContainer} />
                  <SecureRoute path={RouteConstants.COMP_FINDER.route} component={CompFinderContainer} />
                  <SecureRoute path={RouteConstants.EDIT_COMP_FINDER.route} component={CompFinderContainer} />
                  <SecureRoute path={RouteConstants.EDIT_COMPARISON.route} component={EditCompContainer} />
                  <SecureRoute path={RouteConstants.EDIT_PLAYER_LIST.route} component={EditPlayerListContainer} />
                  <SecureRoute path={RouteConstants.EDIT_STAT_LIST.route} component={EditStatListContainer} />
                  <SecureRoute path={RouteConstants.EDIT_STATISTIC.route} component={EditStatContainer} />
                  <SecureRoute path={RouteConstants.HOME_PLATE.route} component={HomePlateContainer} />
                  <SecureRoute path={RouteConstants.LINKS.route} component={HelpfulLinkContainer} />
                  <SecureRoute path={RouteConstants.MY_NEWS_FEED.route} component={NewsFeedContainer} />
                  <SecureRoute path={RouteConstants.MY_SAVED_REPORTS.route} component={SavedReportContainer} />
                  <SecureRoute path={RouteConstants.PLAYERS.route} component={PlayerContainer} />
                  <SecureRoute path={RouteConstants.PLAYER_LISTS.route} component={PlayerListContainer} />
                  <SecureRoute path={RouteConstants.PLAYER_PROFILE.route} component={PlayerProfileContainer} />
                  <SecureRoute path={RouteConstants.PLAYER_QUERIES.route} component={PlayerQueryContainer} />
                  <SecureRoute path={RouteConstants.SHARING.route} component={SharingContainer} />
                  <SecureRoute path={RouteConstants.STAT_LISTS.route} component={StatListContainer} />
                  <SecureRoute path={RouteConstants.STATISTICS.route} component={StatContainer} />
                </PiaNavigation>
                <Route path={RouteConstants.BASE.route} exact component={HomeContainer} />
                <Route path={RouteConstants.LOGIN.route} component={LoginContainer} />
                <Route path={RouteConstants.IMPLICIT_CALLBACK.route} component={Callback} />
                <SecureRoute
                  path={RouteConstants.LOADING_REPORT.route + RouteConstants.LOADING_REPORT.pathParams}
                  component={ReportLoadingContainer}
                />
                <PiaAlerts />
                <PiaLoader />
              </Security>
            </ThemeProvider>
          </Router>
        </Provider>
      </React.Fragment>
    );
  }
}

export default App;
