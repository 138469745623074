import { AlertConstants } from "../../constants/AlertConstants";
import WidgetStore from "../WidgetStore";

class AdminStore extends WidgetStore {
  constructor(authStore, routerStore, alertStore, piaApi) {
    super();
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.alertStore = alertStore;
    this.piaApi = piaApi;
  }

  loadPlayerCache() {
    // load player cache
    this.piaApi.loadPlayerCache([]).then(response => {
      if (!!response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The player cache batch has started."
        });
      }
    });
  }

  loadContractDetails() {
    // load contract details
    this.piaApi.loadContractDetails([]).then(response => {
      if (!!response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The contract details batch has started."
        });
      }
    });
  }

  syncStats() {
    this.piaApi.syncStats().then(response => {
      if (!!response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The stat sync batch has started."
        });
      }
    });
  }
}

export default AdminStore;
