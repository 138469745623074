import { action, computed, decorate, extendObservable, isObservable } from "mobx";
import { AuthConstants } from "../constants/AuthConstants";
import _ from "lodash";
import { CommonConstants } from "../constants/CommonConstants";
import { RouteConstants } from "../constants/RouteConstants";

const ENV = process.env.REACT_APP_ENV;

class AuthStore {
  constructor(routerStore, authApi, piaApi) {
    const authStore = this;
    this.routerStore = routerStore;
    this.authApi = authApi;
    this.piaApi = piaApi;

    if (this.authApi != null && this.authApi.getTokenManager() != null) {
      this.authApi.getTokenManager().on("expired", function(key) {
        console.debug(`OKTA ${key} has expired at`, new Date().getTime().toString());
        authStore.authApi.getTokenManager().renew(key);
      });

      this.authApi.getTokenManager().on("renewed", function(key, newToken) {
        authStore[key] = newToken[key];

        console.debug(`OKTA ${key} expires at`, new Date(newToken.expiresAt * 1000).toString());
      });
    }

    this.defaults = {
      mobileNavOpen: false,
      accessToken: "",
      idToken: "",
      loginError: false,
      isLoggingIn: false,
      isLoggingOut: false,
      refreshInProgress: false,
      idleTimer: null,
      isIdle: false,
      userData: {}
    };

    extendObservable(this, {
      mobileNavOpen: this.defaults["mobileNavOpen"],
      accessToken: this.defaults["accessToken"],
      idToken: this.defaults["idToken"],
      loginError: this.defaults["loginError"],
      isLoggingIn: this.defaults["isLoggingIn"],
      isLoggingOut: this.defaults["isLoggingOut"],
      refreshInProgress: this.defaults["refreshInProgress"],
      idleTimer: this.defaults["idleTimer"],
      isIdle: this.defaults["isIdle"],
      userData: this.defaults["userData"],

      checkLocalStorage: action(() => {
        //Okta reads form localstorage but returns promeses so this is nessecary
        this.userData = localStorage.getItem(AuthConstants.KEYS.USER_DATA)
          ? JSON.parse(localStorage.getItem(AuthConstants.KEYS.USER_DATA))
          : this.defaults["userData"];
        Promise.all(this.authApi.getToken()).then(oktaData => {
          this.accessToken = oktaData[0] ? oktaData[0] : this.defaults["accessToken"];
          this.idToken = oktaData[1] ? oktaData[1] : this.defaults["idToken"];
        });
      }),
      setLoggedIn: action(value => {
        this.accessToken = value.accessToken;
        this.idToken = value.idToken;
      }),
      setLocalStorage: action(userData => {
        if (userData) {
          localStorage.setItem(AuthConstants.KEYS.USER_DATA, JSON.stringify(userData));

          if (userData.boLogonToken) {
            localStorage.setItem(AuthConstants.KEYS.PIA_BO_TOKEN, userData.boLogonToken);
          }
        }
      }),
      setUserData: action(data => {
        this.userData = data;
      }),
      getUserInfo: action(callback => {
        this.piaApi.postlogin().then(
          data => {
            if (data) {
              this.setUserData(data);
              this.setLocalStorage(data);
              this.isLoggingIn = false;

              if (callback) {
                callback();
              }
            }
          },
          error => {
            localStorage.clear();
            this.checkLocalStorage();
            this.userData = this.defaults["userData"];
            this.routerStore.history.push(RouteConstants.LOGIN.route);
          }
        );
      }),
      login: action(() => {
        this.authApi.login();
      }),
      getToken: action(callback => {
        this.isLoggingIn = true;
        Promise.all(this.authApi.getToken()).then(
          data => {
            if (!data || data.error || data.length === 0 || data[0] === undefined || data[1] === undefined) {
              this.isLoggingIn = false;
            } else {
              let token = {
                accessToken: data[0].accessToken,
                idToken: data[1].idToken
              };
              this.setLoggedIn(token);
              this.getUserInfo(callback);
            }
          },
          error => {
            console.error(error);
          }
        );
      }),
      logout: action(() => {
        this.isLoggingOut = true;
        this.piaApi.logout().then(response => {
          localStorage.clear();
          this.checkLocalStorage();
          this.userData = this.defaults["userData"];
          this.idleTimer = this.defaults["idleTimer"];
          this.isIdle = this.defaults["isIdle"];
          this.isLoggingOut = this.defaults["isLoggingOut"];
          this.authApi.logout();
        });
      }),
      refresh: action(() => {}),
      setMobileNavOpen: action(value => {
        this.mobileNavOpen = value;
      })
    });
  }

  get isProd() {
    return !!ENV && ENV.toLowerCase() === "prod";
  }

  get loggedIn() {
    return (
      !!this.accessToken && this.accessToken.length > 0 && this.userData && this.userData.hasOwnProperty("username")
    );
  }

  get isGlobal() {
    let authorities = isObservable(this.userData.authorities)
      ? this.userData.authorities.toJSON()
      : this.userData.authorities;
    return (
      this.loggedIn &&
      _.some(authorities, authority => {
        return authority.authority.toLowerCase() === "global";
      })
    );
  }

  get currentOrg() {
    if (this.userData && this.userData.org) {
      return this.userData.org;
    } else {
      return -1;
    }
  }

  serialize(obj) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  getAccessToken() {
    return this.authApi.getAccessToken();
  }
}

decorate(AuthStore, {
  isProd: computed,
  loggedIn: computed,
  isGlobal: computed,
  currentOrg: computed
});

export default AuthStore;
