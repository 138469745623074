import React from "react";
import { withAuth } from "@okta/okta-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";

class Callback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      error: null
    };
  }

  componentDidMount() {
    this.props.auth
      .handleAuthentication()
      .then(() => {
        this.setState({ authenticated: true });
      })
      .catch(err => this.setState({ authenticated: false, error: err.toString() }));
  }

  render() {
    const { error, authenticated } = this.state;
    if (error) {
      return <p>{error}</p>;
    }

    if (authenticated) {
      return <Redirect to={RouteConstants.HOME_PLATE.route} />;
    }

    return null;
  }
  static propTypes = {
    rootStore: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
  };
}

export default inject("rootStore")(withAuth(observer(Callback)));
