import { OktaAuth } from "@okta/okta-auth-js";
import { RouteConstants } from "../constants/RouteConstants";

/**
 * All Authentication items should go here
 */

class AuthApi {
  constructor(routerStore) {
    this.routerStore = routerStore;
    this.oktaAuth = new OktaAuth({
      issuer: process.env.REACT_APP_OIDC_ISSUER,
      clientId: process.env.REACT_APP_OIDC_CLIENTID,
      scopes: process.env.REACT_APP_OIDC_SCOPE.split(" "),
      redirectUri: window.location.origin + RouteConstants.IMPLICIT_CALLBACK.route,
      pkce: true
    });
    this.oktaAuth.handleAuthentication = this.handleAuthentication.bind(this);
  }

  login() {
    this.oktaAuth.signInWithRedirect({ originalUri: RouteConstants.HOME_PLATE.route });
  }

  async handleAuthentication() {
    if (this.oktaAuth.isLoginRedirect()) {
      await this.oktaAuth.token.parseFromUrl().then(data => {
        const { accessToken, idToken } = data.tokens;
        this.oktaAuth.tokenManager.add("accessToken", accessToken);
        this.oktaAuth.tokenManager.add("idToken", idToken);
      });
    }
  }

  logout() {
    this.oktaAuth.signOut();
  }

  getToken = () => {
    return [this.oktaAuth.tokenManager.get("accessToken"), this.oktaAuth.tokenManager.get("idToken")];
  };

  getUserInfo = () => {
    return this.oktaAuth.getUser();
  };

  getAccessToken = () => {
    return this.oktaAuth.tokenManager.get("accessToken");
  };

  getTokenManager = () => {
    return this.oktaAuth.tokenManager;
  };
}

export default AuthApi;
